<template>
  <div class="roles-permission-mobile-view">
    <v-navigation-drawer class="p-6" permanent floating width="100%">
      <v-list>
        <draggable
          :list="panelOptions"
          group="cards"
          :class="
            $vuetify.breakpoint.smAndDown
              ? ''
              : 'row fill-width overflow-x-auto no-wrap'
          "
          class="my-0"
          @end="dragColumn($event)"
        >
          <div
            v-for="(key, i) in panelOptions"
            :key="i"
            link
            @click="showSelectedpanel(key)"
          >
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 24 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11 18C11 19.1 10.1 20 9 20C7.9 20 7 19.1 7 18C7 16.9 7.9 16 9 16C10.1 16 11 16.9 11 18ZM9 10C7.9 10 7 10.9 7 12C7 13.1 7.9 14 9 14C10.1 14 11 13.1 11 12C11 10.9 10.1 10 9 10ZM9 4C7.9 4 7 4.9 7 6C7 7.1 7.9 8 9 8C10.1 8 11 7.1 11 6C11 4.9 10.1 4 9 4ZM15 8C16.1 8 17 7.1 17 6C17 4.9 16.1 4 15 4C13.9 4 13 4.9 13 6C13 7.1 13.9 8 15 8ZM15 10C13.9 10 13 10.9 13 12C13 13.1 13.9 14 15 14C16.1 14 17 13.1 17 12C17 10.9 16.1 10 15 10ZM15 16C13.9 16 13 16.9 13 18C13 19.1 13.9 20 15 20C16.1 20 17 19.1 17 18C17 16.9 16.1 16 15 16Z"
                      fill="#757575"
                    />
                  </svg>
                  <span class="subtitle-1 font-weight-medium">
                    {{ $t(key) }}</span
                  >
                  <v-btn
                    elevation="0"
                    fab
                    outlined
                    width="20"
                    height="20"
                    v-if="key != 'maps'"
                    class="ml-2 text-caption font-weight-regular"
                    color="primary"
                  >
                    {{
                      key === "notes"
                        ? notesCount
                        : key === "imagesAttachments"
                          ? attachmentsCount
                          : 0
                    }}</v-btn
                  >
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon>{{ "mdi-chevron-right" }}</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-divider></v-divider>
          </div>
        </draggable>
      </v-list>
    </v-navigation-drawer>
    <v-dialog v-model="dialog" fullscreen max-width="500px">
      <v-card>
        <v-card-title class="px-3">
          <v-row>
            <v-col cols="9"
              ><v-btn
                icon
                aria-label="close"
                class="pr-2"
                @click="dialog = false"
              >
                <v-icon>mdi-arrow-left</v-icon>
              </v-btn>
              <span class="text-h6 font-weight-medium">{{
                $t(selectedPanel)
              }}</span></v-col
            >
            <v-col cols="3" class="px-0"> </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="px-0">
          <MapView
            :ticket-details="ticketDetails"
            :if-inside-dialog="true"
            v-if="selectedPanel == 'maps' && viewMap"
          />
          <Notes
            :ticket-details="ticketDetails"
            v-if="selectedPanel == 'notes'"
          />
          <ImagesAttachments
            :ticket-details="ticketDetails"
            :ticket-attachments="ticketDetails.attachments"
            v-if="selectedPanel == 'imagesAttachments'"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Notes from "./Notes.vue";
import MapView from "./MapView.vue";
import ImagesAttachments from "./ImagesAttachments.vue";
import UserDataMixin from "@/mixins/UserData";
import Draggable from "vuedraggable";
export default {
  components: {
    Notes,
    MapView,
    ImagesAttachments,
    Draggable,
  },
  mixins: [UserDataMixin],
  props: {
    ticketDetails: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dialog: false,
      panelOptions: [],
      selectedPanel: "",
      viewMap: false,
    };
  },
  created() {
    let panels = [];
    if (this.userData.options.panelOptions) {
      let userPanelOptions = this.userData.options.panelOptions;
      userPanelOptions.forEach((element) => {
        if (this.checkPermission("viewWorkArea") && element == "maps")
          panels.push("maps");
        if (
          this.checkPermission("ticketAttachments") &&
          element == "imagesAttachments"
        )
          panels.push("imagesAttachments");
        if (this.checkPermission("ticketNotes") && element == "notes")
          panels.push("notes");
      });
      this.panelOptions = panels;
    } else {
      if (this.checkPermission("viewWorkArea")) panels.push("maps");
      if (this.checkPermission("ticketAttachments"))
        panels.push("imagesAttachments");
      if (this.checkPermission("ticketNotes")) panels.push("notes");
      this.panelOptions = panels;
      this.dragColumn();
    }
    this.expanded = [this.panelOptions.indexOf("maps")];
  },
  computed: {
    userData() {
      return this.$store.state.User.user;
    },
    notesCount() {
      return this.ticketDetails.notes ? this.ticketDetails.notes.length : 0;
    },
    attachmentsCount() {
      return this.ticketDetails.attachments
        ? this.ticketDetails.attachments.length
        : 0;
    },
  },
  methods: {
    async dragColumn() {
      let options = this.userData.options;
      options.panelOptions = [...this.panelOptions];
      const result = await this.$store.dispatch("updateUser", {
        id: this.userData.user_id,
        options: options,
      });
      const userData = await this.$store.dispatch("getUserDataById");
      if (userData.status !== "error") {
        this.$store.commit("setUserData", userData[0]);
      }
    },
    showSelectedpanel(key) {
      this.dialog = true;
      this.selectedPanel = key;
      setTimeout(() => {
        this.viewMap = true;
      });
    },
    close() {
      this.dialog = false;
    },
  },
};
</script>
<style lang="scss">
@import "../../../scss/components/roles-and-permissions.scss";

.roles-permission-mobile-view {
  width: 100%;
}

.no-permission-indicator {
  color: #c35309;
  padding-top: 2px;
}
</style>
