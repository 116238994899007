var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "roles-permission-mobile-view" },
    [
      _c(
        "v-navigation-drawer",
        {
          staticClass: "p-6",
          attrs: { permanent: "", floating: "", width: "100%" },
        },
        [
          _c(
            "v-list",
            [
              _c(
                "draggable",
                {
                  staticClass: "my-0",
                  class: _vm.$vuetify.breakpoint.smAndDown
                    ? ""
                    : "row fill-width overflow-x-auto no-wrap",
                  attrs: { list: _vm.panelOptions, group: "cards" },
                  on: {
                    end: function ($event) {
                      return _vm.dragColumn($event)
                    },
                  },
                },
                _vm._l(_vm.panelOptions, function (key, i) {
                  return _c(
                    "div",
                    {
                      key: i,
                      attrs: { link: "" },
                      on: {
                        click: function ($event) {
                          return _vm.showSelectedpanel(key)
                        },
                      },
                    },
                    [
                      _c(
                        "v-list-item",
                        [
                          _c(
                            "v-list-item-content",
                            [
                              _c(
                                "v-list-item-title",
                                [
                                  _c(
                                    "svg",
                                    {
                                      attrs: {
                                        width: "20",
                                        height: "20",
                                        viewBox: "0 0 24 16",
                                        fill: "none",
                                        xmlns: "http://www.w3.org/2000/svg",
                                      },
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          d: "M11 18C11 19.1 10.1 20 9 20C7.9 20 7 19.1 7 18C7 16.9 7.9 16 9 16C10.1 16 11 16.9 11 18ZM9 10C7.9 10 7 10.9 7 12C7 13.1 7.9 14 9 14C10.1 14 11 13.1 11 12C11 10.9 10.1 10 9 10ZM9 4C7.9 4 7 4.9 7 6C7 7.1 7.9 8 9 8C10.1 8 11 7.1 11 6C11 4.9 10.1 4 9 4ZM15 8C16.1 8 17 7.1 17 6C17 4.9 16.1 4 15 4C13.9 4 13 4.9 13 6C13 7.1 13.9 8 15 8ZM15 10C13.9 10 13 10.9 13 12C13 13.1 13.9 14 15 14C16.1 14 17 13.1 17 12C17 10.9 16.1 10 15 10ZM15 16C13.9 16 13 16.9 13 18C13 19.1 13.9 20 15 20C16.1 20 17 19.1 17 18C17 16.9 16.1 16 15 16Z",
                                          fill: "#757575",
                                        },
                                      }),
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "subtitle-1 font-weight-medium",
                                    },
                                    [_vm._v(" " + _vm._s(_vm.$t(key)))]
                                  ),
                                  key != "maps"
                                    ? _c(
                                        "v-btn",
                                        {
                                          staticClass:
                                            "ml-2 text-caption font-weight-regular",
                                          attrs: {
                                            elevation: "0",
                                            fab: "",
                                            outlined: "",
                                            width: "20",
                                            height: "20",
                                            color: "primary",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                key === "notes"
                                                  ? _vm.notesCount
                                                  : key === "imagesAttachments"
                                                  ? _vm.attachmentsCount
                                                  : 0
                                              )
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-icon",
                            [
                              _c("v-icon", [
                                _vm._v(_vm._s("mdi-chevron-right")),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-divider"),
                    ],
                    1
                  )
                }),
                0
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { fullscreen: "", "max-width": "500px" },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "px-3" },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "9" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "pr-2",
                              attrs: { icon: "", "aria-label": "close" },
                              on: {
                                click: function ($event) {
                                  _vm.dialog = false
                                },
                              },
                            },
                            [_c("v-icon", [_vm._v("mdi-arrow-left")])],
                            1
                          ),
                          _c(
                            "span",
                            { staticClass: "text-h6 font-weight-medium" },
                            [_vm._v(_vm._s(_vm.$t(_vm.selectedPanel)))]
                          ),
                        ],
                        1
                      ),
                      _c("v-col", {
                        staticClass: "px-0",
                        attrs: { cols: "3" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-text",
                { staticClass: "px-0" },
                [
                  _vm.selectedPanel == "maps" && _vm.viewMap
                    ? _c("MapView", {
                        attrs: {
                          "ticket-details": _vm.ticketDetails,
                          "if-inside-dialog": true,
                        },
                      })
                    : _vm._e(),
                  _vm.selectedPanel == "notes"
                    ? _c("Notes", {
                        attrs: { "ticket-details": _vm.ticketDetails },
                      })
                    : _vm._e(),
                  _vm.selectedPanel == "imagesAttachments"
                    ? _c("ImagesAttachments", {
                        attrs: {
                          "ticket-details": _vm.ticketDetails,
                          "ticket-attachments": _vm.ticketDetails.attachments,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }